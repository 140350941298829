import React from 'react';
import {
    Row,
    Col,
} from 'reactstrap';

const CartItem = (props) => {
    return (
        <Row key={props.id} style= {{
            border: '0.5px dashed',
            borderRadius: '10px',
            margin: '0 1vw 1vh 1vw'
        }}>
            {/* Item image */}
            <Col>
                <img src={props.image} alt={props.description} />
            </Col>
            {/* Item Description */}
            <Col>
                <h3>{props.name}</h3>
                <h4>Quantity: {props.quantity}</h4>
                <span style={{
                    fontSize: '.8rem',
                    display: 'flex'
                }}>
                    <p>Delete</p>
                    <p style={{margin: '0 10px'}}> | </p>
                    <p>Save for later</p>
                    <p style={{ margin: '0 10px' }}> | </p>
                    <p>Compare with similar items</p>
                </span>
            </Col>
            {/* Price */}
            <Col>
                <h3>{props.cost}</h3>
            </Col>
        </Row>
    )
}

export default CartItem;
import React from 'react';
import { Link } from "react-router-dom";
import {
    Card,
    CardImg,
    CardBody,
    CardSubtitle,
    CardTitle,
    CardFooter,
    Button
} from 'reactstrap';

const CourseCard = (props) => {
    return (
        // <Card onClick={() => props.onImgClick(props.id)} className='shadow' style={{
        <Card className='shadow card-hover' style={{
            borderRadius: '25px',
            margin: '3vh 4vw 0 4vw'
        }}>
            <CardImg
                alt={props.description}
                src={props.source}
                top
                style={{
                    borderRadius: '25px 25px 0 0',
                    maxHeight: '25vh',
                    objectFit: 'cover'
                }}
            />
            <CardBody>
                {<CardTitle className='uppercase-font' style={{
                    display: 'inline',
                    fontWeight: 'bold',
                    fontSize: 'calc(10px + 2vmin)'
                }}>{props.name}</CardTitle>}
                {<CardSubtitle style={{
                    fontSize: 'calc(10px + 1.4vmin)'
                }}>{props.description}</CardSubtitle>}
            </CardBody>
            <CardFooter style={{
                borderRadius: '0 0 25px 25px'
            }}>
                <Link to='/courses'>
                    <Button color='warning'>Get started</Button>
                </Link>
            </CardFooter>
        </Card>
    )
}

export default CourseCard;
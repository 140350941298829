import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';
import Loading from '../components/Loading';
import ArticleCard from '../components/ArticleCard';

const ArticlesPage = () => {
    const [articles, setArticles] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        // Google Analytics tracking
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });

        // Fetching articles from server
        async function fetchArticles() {
            try {
                // Make a GET request to the server for all articles
                const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/articles/`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                // Throw an error if the response is not ok
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Parse the JSON response
                const data = await response.json();
                // Throw an error if the response data is empty
                if (data.length === 0) {
                    throw new Error('No articles found');
                }
                // Set the articles state with the response data
                setArticles(data);
            } catch (error) {
                // Log the error to the console
                console.error(error);
                // Set the error message in the state
                setErrorMessage(error.message);
            }
        }
        // If the articles state is empty, fetch the articles
        if (articles === "") {
            fetchArticles();
        }
        // The useEffect hook depends on the articles state
    });

    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Articles
                </CardTitle>
                    {
                        (articles)
                            ?
                            (articles).map((item, key) => (
                                <Link
                                    to={'/article-details/' + item.id}
                                    key={key}
                                >
                                    <ArticleCard
                                        key={key}
                                        id={item.id}
                                        image={item.imageURL}
                                        title={item.title}
                                        author={item.author}
                                        date={item.date}
                                        content={item.content}
                                    />
                                </Link>
                            ))
                            :
                            (!errorMessage)
                                ?
                                <Loading />
                                :
                                <div>{errorMessage}</div>
                    }
            </CardBody>
        </Card>

    );
};

export default ArticlesPage;
import React, { useEffect, useState } from 'react';
import ReactGA from "react-ga4";
import Video from '../components/Video'
import {
    Card,
    CardBody,
    CardTitle,
    Button
} from 'reactstrap';

function MediaPage() {

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    });

    // State to track which button is clicked
    const [activeDiv, setActiveDiv] = useState(null);

    // Function to handle button click
    const handleClick = (divName) => {
        setActiveDiv(divName);
    };

    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Videos
                </CardTitle>

                <div>
                    <Button onClick={() => handleClick('div1')}>All Topics</Button>
                    <Button onClick={() => handleClick('div2')}>Gratitude</Button>
                    <Button onClick={() => handleClick('div3')}>Reiki</Button>
                    <Button onClick={() => handleClick('div4')}>Health & Wellness</Button>
                    <Button onClick={() => handleClick('div5')}>Meditation</Button>
                    <Button onClick={() => handleClick('div6')}>Mindfulness</Button>
                </div>
                <div>
                    {
                        activeDiv === 'div1'
                        &&
                        <div>
                            <h3 style={{
                                textAlign: 'left'
                            }}>
                                All IgniteYourGnost Videos:
                            </h3>
                            <Video
                                embedId='https://www.youtube.com/playlist?list=PLDo6uTXiLcCYkSRjJUX_D1Tl9BxUcH3wu'
                            />
                        </div>
                    }

                    {
                        activeDiv === 'div2'
                        &&
                        <div>
                            <h3 style={{
                                textAlign: 'left'
                            }}>
                                Gratitude Videos:
                            </h3>
                            <Video
                                embedId='https://www.youtube.com/playlist?list=PLDo6uTXiLcCapEGfdIXrRzIUJgbnmfa_X'
                            />
                        </div>
                    }

                    {
                        activeDiv === 'div3'
                        &&
                        <div>
                            <h3 style={{
                                textAlign: 'left'
                            }}>
                                Reiki Videos:
                            </h3>
                            <Video
                                embedId='https://youtube.com/playlist?list=PLDo6uTXiLcCY93dE65odI5B2f0qUDHzPa&si=XLrzneL1XSBK8n-4'
                            />
                        </div>
                    }

                    {
                        activeDiv === 'div4'
                        &&
                        <div>
                            <h3 style={{
                                textAlign: 'left'
                            }}>
                                Health & Wellness Videos:
                            </h3>
                            <Video
                                embedId='https://www.youtube.com/playlist?list=PLDo6uTXiLcCY7_zwZmmXRYtMrZhQnV6Np'
                            />
                        </div>
                    }

                    {
                        activeDiv === 'div5'
                        &&
                        <div>
                            <h3 style={{
                                textAlign: 'left'
                            }}>
                                Meditation Videos:
                            </h3>
                            <Video
                                embedId='https://youtube.com/playlist?list=PLDo6uTXiLcCaYK4URWe4zVCdddgruCCQW&si=fQvPALRuc83oFcBB'
                            />
                        </div>
                    }

                    {
                        activeDiv === 'div6'
                        &&
                        <div>
                            <h3 style={{
                                textAlign: 'left'
                            }}>
                                Mindfulness Videos:
                            </h3>
                            <Video
                                embedId='https://youtube.com/playlist?list=PLDo6uTXiLcCZVNOTVya-9-8FVmkA2KIm5&si=bVQ4FUVoE7hD7-d3'
                            />
                        </div>
                    }
                </div>













            </CardBody>
        </Card>
    );
}

export default MediaPage;
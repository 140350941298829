import React from 'react';
import {
    Card,
    CardTitle,
    CardText,
    CardSubtitle
} from 'reactstrap';
import { Link } from "react-router-dom";
import './Components.css';
import xIcon from '../assets/icons/x-icon.png';
import youtubeIcon from '../assets/icons/youtube-icon.png';
import linkedinIcon from '../assets/icons/linkedin-icon.png';
import facebookIcon from '../assets/icons/facebook-icon.png';
import instagramIcon from '../assets/icons/instagram-icon.png';
import pinterestIcon from '../assets/icons/pinterest-icon.png';
import rumbleIcon from '../assets/icons/rumble-icon.png';

export default function FooterComponent() {

    return (
        <Card id='footer' style={{
            backgroundColor: '#8F8389',
            width: '100%',

        }}>
            <CardTitle style={{
                marginTop: '2vh'
            }}>
                <Link to='contact' className='uppercase-font' style={{ marginRight: '2%', fontSize: '1.8rem' }}>Contact Us</Link>
                <Link to='help' className='uppercase-font' style={{ marginRight: '2%', fontSize: '1.8rem' }}>Help/FAQ</Link>
                <Link to='newsletter' className='uppercase-font' style={{ marginRight: '2%', fontSize: '1.8rem' }}>Newsletter</Link>
                <Link to='careers' className='uppercase-font' style={{ fontSize: '1.8rem' }} >Careers</Link>
            </CardTitle>
            <CardSubtitle style={{
                minHeight: '50px'
            }}>
                <a href='https://twitter.com/IgniteGnost' target='new'>
                    <img src={xIcon} alt='x icon' className='socialLink' />
                </a>
                
                <a href='https://www.facebook.com/profile.php?id=61555250354932' target='new'>
                    <img src={facebookIcon} alt='facebook icon' className='socialLink' />
                </a>
                <a href='https://www.instagram.com/ignitegnost/' target='new'>
                    <img src={instagramIcon} alt='instagram icon' className='socialLink' />
                </a>
                {/* <a href='https://www.pinterest.com/' target='new'>
                    <img src={pinterestIcon} alt='pinterest icon' className='socialLink' />
                </a> */}
                {/* <a href='https://www.linkedin.com/' target='new'>
                    <img src={linkedinIcon} alt='linkedin icon' className='socialLink' />
                </a> */}
                <a href='https://www.youtube.com/channel/UCrChkJKypQ2dQoXdrtsSsAg' target='new'>
                    <img src={youtubeIcon} alt='youtube icon' className='socialLink' />
                </a>
                <a href='https://rumble.com/c/c-5576289' target='new'>
                    <img src={rumbleIcon} alt='rumble icon' className='socialLink' />
                </a>
            </CardSubtitle>
            <CardText style={{
                color: 'black',
                margin: '1vh 0 0 0',
                fontSize: '1.2rem'
            }}>
                ©2024 IgniteYourGnost, All Rights Reserved.
            </CardText>
            <CardText style={{
                fontSize: '1.2rem',
                marginBottom: '1vh'
            }}>
                <Link to='privacy-policy'>Privacy</Link> |
                <Link to='terms-of-use'> Terms of Service</Link> |
                <Link to='disclaimer'> Disclaimer</Link> |
                <Link to='accessibility'> Accessibility Statement</Link>
            </CardText>
        </Card>
    );
}


import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import {
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';

function ReceiptPage() {

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    });

    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
            <CardTitle className='cursive-font' style={{
                fontSize: '2.2em'
            }}>
                Account
            </CardTitle>
            

            </CardBody>
        </Card>
    );
}

export default ReceiptPage;
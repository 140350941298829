import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import {
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Row,
    Col,
    Button
} from 'reactstrap';
import { Link } from "react-router-dom";
// import StripeContainer from '../stripe/StripeContainer';

function CheckoutPage() {

    // const [total, setTotal] = useState("0.00");
    // const [address, setAddress] = useState("");


    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    });

    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Check Out
                </CardTitle>
                {/* Shipping Information */}
                <Row>
                    <Col>
                        <h3>1</h3>
                    </Col>
                    <Col>
                        <h3>Shipping Address</h3>
                    </Col>
                    <Col>
                        {/* {address} */}
                    </Col>
                    <Col>
                        <Button color='secondary'>
                            Change
                        </Button>
                    </Col>
                </Row>
                {/* Payment Information */}
                <Row>
                    <Col>
                        <h3>2</h3>
                    </Col>
                    <Col>
                        <h3>Payment Method</h3>
                    </Col>
                    <Col>
                        {/* <StripeContainer /> */}
                    </Col>
                    <Col>
                        <Button color='secondary'>
                            Change
                        </Button>
                    </Col>
                </Row>
                {/* Item Review*/}
                <Row>
                    <Col>
                        <h3>3</h3>
                    </Col>
                    <Col>
                        <h3>Review Items</h3>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </Row>
                {/* Cart */}
                <Row>
                    <Col>
                        <h3>Cart placeholder</h3>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <Button color='warning'>
                    Place Order
                </Button>
                <h4 style={{
                    color: 'red'
                }}>
                    {/* Order Total: ${total} */}
                </h4>
                <h5>By placing your order, you agree to our <Link to={'/privacy-policy'}>privacy notice</Link> and <Link to={'/terms-of-use'}>terms of service</Link></h5>
            </CardFooter>
        </Card>
    );
}

export default CheckoutPage;

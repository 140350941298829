import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CardFooter,
    Button
} from 'reactstrap';
import Loading from '../components/Loading';

function ArticleDetailsPage() {
    const [article, setArticle] = useState('');

    useEffect(() => {
        // Send pageview event to Google Analytics
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: window.location.pathname });

        if (!article) { // Check if article is not yet set
            const pathname = window.location.pathname;

            // Extract the article ID from the URL
            const articleID = pathname.split('/').pop();

            // Fetch article data from the server
            fetch(`${ process.env.REACT_APP_API_URL }api/v1/articles/${articleID}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setArticle(data);
                })
                .catch((err) => {
                    console.error('Error: ', err);
                });
        }
    }, [article]); // Add article to dependency array to prevent multiple requests

    return (
        <>
            {article ? (
                <Card
                    className="shadow"
                    style={{
                        width: '80vw',
                        minHeight: '60vh',
                        borderRadius: '25px',
                        margin: '8vh auto',
                    }}
                >
                    <CardBody>
                        <CardImg
                            src={`${article.imageURL}`}
                            alt={`${article.title}`}
                            style={{
                                height: '40vh',
                                objectFit: 'cover',
                                borderRadius: '25px 25px 0 0',
                                maxHeight: '40vh',
                            }}
                        />
                        <CardTitle
                            className="cursive-font"
                            style={{
                                fontSize: '2.2em',
                                marginTop: '4vh'
                            }}
                        >
                            {article.title}
                        </CardTitle>
                        <div style={{
                            whiteSpace: 'pre-wrap',
                            textAlign: 'left'
                        }}>
                            {article.content}
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Link to='/articles'>
                        <Button color='primary'>
                            Back to Articles
                        </Button>
                    </Link>
                </CardFooter>
                </Card >
            ) : (
        <Card
            className="shadow"
            style={{
                width: '80vw',
                minHeight: '60vh',
                borderRadius: '25px',
                margin: '8vh auto',
            }}
        >
            <CardBody>
                <Loading />
            </CardBody>
        </Card >
    )
}
        </>



    );
}

export default ArticleDetailsPage;

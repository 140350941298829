import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    FormGroup,
    Form,
    Input,
    Button
} from 'reactstrap';

// Add to form - password, phone number, order history, address, 



function AccountPage() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');


    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    });

    function handleSubmit(event) {
        event.preventDefault();
        // Need to add user's information to db
    };

    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    My Account
                </CardTitle>

                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Input type="text" placeholder="first name" onChange={e => setFirstName(e.target.value)} value={firstName} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Input type="text" placeholder="last name" onChange={e => setLastName(e.target.value)} value={lastName} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Input type="email" placeholder="email" onChange={e => setEmail(e.target.value)} value={email} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Input type="textarea" placeholder="write your message here" onChange={e => setMessage(e.target.value)} value={message} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <FormGroup style={{
                            margin: 'auto',
                        }}>
                            <Button color='primary' style={{ margin: '5px' }} >Submit</Button>
                            <Button color='warning' style={{ margin: '5px' }} >Edit</Button>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup style={{
                            margin: 'auto',
                        }}>
                            <Button color='danger' style={{ margin: '5px' }} >Delete Account</Button>
                        </FormGroup>
                    </Row>
                </Form>

            </CardBody>
        </Card>
    );
}

export default AccountPage;
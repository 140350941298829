import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import { Link, useLocation } from "react-router-dom";
import {
    CardGroup,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    CardImg,
    Row,
    Col,
    FormGroup,
    Form,
    FormFeedback,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import Loading from '../../components/Loading';
import ArticleCard from '../../components/ArticleCard';

function AdminArticlesPage() {

    // State for adding a new article
    const [image, setImage] = useState(null);
    const [acceptImage, setAcceptImage] = useState(false);
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [author, setAuthor] = useState('');
    const [content, setContent] = useState('');

    // State for form validation
    const [errors, setErrors] = useState({});

    // State for editing an article
    const [newTitle, setNewTitle] = useState('');
    const [newContent, setNewContent] = useState('');

    // State for editing and deleting articles
    const [articles, setArticles] = useState('');
    // const [confirmDelete, setConfirmDelete] = useState(false);
    const [modalTitle, setModalTitle] = useState('title');
    const [modalId, setModalId] = useState('');

    // State for Loading component and display messages
    const [isLoading, setIsLoading] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    // State for managing delete modal
    const [modal, setModal] = useState(false);


    // Parse URL - determine which form to show
    const location = useLocation();
    const operation = location.pathname.split('/').pop();

    useEffect(() => {

        // Google Analytics
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });

        const shouldFetchArticles = (operation === 'edit' || operation === 'delete') && !articles;

        if (shouldFetchArticles) {
            fetchArticles();
        }
    }, [operation, articles],);


    // Fetching articles from server
    async function fetchArticles() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/articles/`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data.length === 0) {
                throw new Error('No articles found');
            }

            const formattedArticles = formatArticleDates(data);
            setArticles(formattedArticles);

        } catch (error) {
            console.error(error);
        }
    }

    function formatArticleDates(data) {
        return data.map(article => {
            const date = new Date(article.date);
            const options = { dateStyle: 'medium' };
            const dateStr = date.toLocaleDateString(undefined, options);
            return { ...article, date: dateStr };
        });
    }



    // Toggle delete article modal
    const toggle = () => setModal(!modal);
    
    // Validate new article entry form
    const validateNewArticleForm = () => {
        const newErrors = {};

        // An array defining the fields to be validated, their values, any custom validation logic, and error messages
        const fields = [
            {
                name: 'image',
                value: acceptImage,
                customValidation: () => !acceptImage,  // Custom validation to check if the image is accepted
                errorMessage: 'image is required'
            },
            {
                name: 'title',
                value: title,
                errorMessage: 'title is required'
            },
            {
                name: 'date',
                value: date,
                errorMessage: 'date is required'
            },
            {
                name: 'author',
                value: author,
                errorMessage: 'author is required'
            },
            {
                name: 'content',
                value: content,
                errorMessage: 'content is required'
            },
        ];

        let valid = true;

        // Iterate over each field for validation
        fields.forEach(field => {
            // If a custom validation function is provided, use it. Otherwise, check if the field value is blank.
            if (field.customValidation ? field.customValidation() : field.value.trim() === '') {
                newErrors[field.name] = field.errorMessage;  // Set the error message for invalid fields
                valid = false;
            }
        });

        // Update the state with any validation errors
        setErrors(newErrors);

        // Return the overall validity of the form (true if all fields are valid)
        return valid;
    };

    const validateEditForm = () => {
        let valid = true;
        const newErrors = {};
        // Validating new title field
        if (newTitle.trim() === '') {
            newErrors.newTitle = 'new title is required';
            valid = false;
        }
        // Validating new content field
        if (newContent.trim() === '') {
            newErrors.newContent = 'new content is required';
            valid = false;
        }
        setErrors(newErrors);
        return valid;
    };

    // Publish an article
    const publishArticle = async e => {
        e.preventDefault();

        // Check the form validity
        if (!validateNewArticleForm()) {
            console.log('Form has errors');
            return;
        }

        setIsLoading(true);

        try {
            // Step 1: Get the pre-signed URL from your backend
            const presignedUrlResponse = await fetch(`${process.env.REACT_APP_API_URL}api/v1/get-presigned-url`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ fileName: image.name, fileType: image.type }), // Assuming `image` is a File or Blob with name and type properties
            });

            if (!presignedUrlResponse.ok) {
                throw new Error('Failed to get the presigned URL.');
            }
      
            const { url } = await presignedUrlResponse.json();

            // Step 2: Upload the image to S3 using the pre-signed URL
            const s3UploadResponse = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': image.type, // Ensure this matches the Content-Type set when generating the presigned URL
                },
                body: image,  // Send the file data directly
            });
            // console.error('S3 upload error:', await s3UploadResponse.text());
            if (!s3UploadResponse.ok) {
                throw new Error('Failed to upload image to S3.');
            }

            // Step 3: Send the article details (excluding the image) to your backend
            const newArticle = {
                title,
                content,
                author,
                date,
                imageURL: image.name
            };

            console.log(newArticle.imageURL);

            const articleResponse = await fetch(`${process.env.REACT_APP_API_URL}api/v1/articles/admin/new`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newArticle),
            });

            if (!articleResponse.ok) {
                throw new Error('Failed to create the article.');
            }

            // Reset the form and related states if everything went well
            setIsPublished(true);
            setImage(null);
            setAcceptImage(false);
            setTitle('');
            setDate('');
            setAuthor('');
            setContent('');

        } catch (err) {
            console.error('Error publishing article:', err.message);
        } finally {
            setIsLoading(false); // Ensure loading state is reset irrespective of success or failure
        }
    };


    // Edit selected article
    function handleEdit(id) {

        // Form validation
        if (validateEditForm()) {
            fetch(`${ process.env.REACT_APP_API_URL }api/v1/articles/admin/edit/${id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    title: newTitle,
                    content: newContent
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    // handle response
                    // need to notify was updated
                    setIsEdited(true);
                })
                .catch(error => {
                    console.error('Error updating article:', error);
                });
        }
    }

    // Select article for delete - trigger modal
    function handleSelectForDeletion(id, title) {
        toggle();
        setModalTitle(title);
        setModalId(id);
    }

    // Delete selected article
    function handleDelete() {
        toggle();
        fetch(`${process.env.REACT_APP_API_URL}api/v1/articles/admin/delete/${modalId}`, {
            method: 'DELETE',
        })
            .then(() => {
                // Remove the deleted article from the list of articles
                setArticles(articles.filter(article => article.id !== modalId));
                setIsDeleted(true);
            })
            .catch((err) => {
                console.error('Error deleting article: ', err);
            });
    }


    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Admin - Articles
                </CardTitle>

                {/* Show new page */}
                {
                    operation === 'new'
                    &&
                    < Form onSubmit={publishArticle}>
                        <Row>
                            <Col>
                                <div style={{ marginTop: '5vh' }}>
                                    {/* If an image has been selected, it will be displayed here */}
                                    {image && (
                                        <Card>
                                            <CardImg
                                                alt="not found"
                                                src={URL.createObjectURL(image)}
                                                style={{
                                                    maxHeight: '20vh',
                                                    filter: 'brightness(85%)',
                                                    objectFit: 'contain'
                                                }}
                                            />

                                            {
                                                (acceptImage)
                                                    ?
                                                    <CardBody />
                                                    :
                                                    <CardBody>
                                                        <Button
                                                            color='danger'
                                                            style={{ marginRight: '10px' }}
                                                            onClick={() => setImage(null)}
                                                        >
                                                            Remove
                                                        </Button>
                                                        <Button
                                                            color='success'
                                                            onClick={() => setAcceptImage(true)}
                                                        >
                                                            Accept
                                                        </Button>
                                                    </CardBody>
                                            }

                                        </Card>
                                    )}
                                </div>
                                {
                                    (acceptImage)
                                        ?
                                        <FormGroup />
                                        :
                                        <FormGroup>
                                            <Input
                                                type="file"
                                                name="image"
                                                style={{
                                                    fontSize: 'calc(10px + 1.3vmin)'
                                                }}
                                                onChange={(e) => {
                                                    setImage(e.target.files[0]);
                                                }}
                                                invalid={errors.image !== undefined}
                                            />
                                        </FormGroup>
                                }

                                {errors.image && <FormFeedback>{errors.image}</FormFeedback>}
                                <FormGroup>
                                    <Input
                                        type="text"
                                        name="title"
                                        placeholder="title"
                                        value={title}
                                        onChange={e => setTitle(e.target.value)}
                                        invalid={errors.title !== undefined}
                                    />
                                </FormGroup>
                                {errors.title && <FormFeedback>{errors.title}</FormFeedback>}
                                <FormGroup>
                                    <Input
                                        type="date"
                                        name="date"
                                        placeholder="date"
                                        value={date}
                                        onChange={e => setDate(e.target.value)}
                                        invalid={errors.date !== undefined}
                                    />
                                </FormGroup>
                                {errors.date && <FormFeedback>{errors.date}</FormFeedback>}
                                <FormGroup>
                                    <Input
                                        type="text"
                                        name="author"
                                        placeholder="author"
                                        value={author}
                                        onChange={e => setAuthor(e.target.value)}
                                        invalid={errors.author !== undefined}
                                    />
                                </FormGroup>
                                {errors.author && <FormFeedback>{errors.author}</FormFeedback>}
                                <FormGroup>
                                    <Input
                                        type="textarea" name="content"
                                        placeholder="content"
                                        value={content}
                                        onChange={e => setContent(e.target.value)}
                                        invalid={errors.content !== undefined}
                                    />
                                </FormGroup>
                                {errors.content && <FormFeedback>{errors.content}</FormFeedback>}

                                {/* Publish new article */}
                                <Button
                                    color='success'
                                    style={{ marginRight: '10px' }}
                                    type='submit'
                                >
                                    Publish
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                }

                {/* Article successfully published display*/}
                {
                    (isPublished)
                        ?

                        <div>
                            <p style={{
                                color: 'darkgreen',
                                weight: 'bold'
                            }}>Article successfully published!</p>
                            <Link to='/articles'>
                                <Button color='primary'>
                                    View
                                </Button>
                            </Link>
                        </div>
                        :
                        <div />
                }



                {/* Show edit page */}
                {
                    operation === 'edit'
                    &&
                    <div>
                        <Form>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="newTitle"
                                    placeholder="new title"
                                    value={newTitle}
                                    onChange={e => setNewTitle(e.target.value)}
                                    invalid={errors.newTitle !== undefined}
                                />
                            </FormGroup>
                            {errors.newTitle && <FormFeedback>{errors.newTitle}</FormFeedback>}
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    name="newContent"
                                    placeholder="new content"
                                    value={newContent}
                                    onChange={e => setNewContent(e.target.value)}
                                    invalid={errors.newContent !== undefined}
                                />
                            </FormGroup>
                            {errors.newContent && <FormFeedback>{errors.newContent}</FormFeedback>}
                        </Form>
                        <CardGroup style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                                {
                                    articles
                                        ?
                                        articles.map(article => (
                                            <div key={article.id}>
                                                <ArticleCard
                                                    key={article.id}
                                                    id={article.id}
                                                    image={article.imageURL}
                                                    title={article.title}
                                                    author={article.author}
                                                    date={article.date}
                                                    content={article.content}
                                                />
                                                <Button color='primary' onClick={() => handleEdit(article.id)}>Select</Button>
                                            </div>
                                        ))
                                        :
                                        <Loading/>
                                }
                        </CardGroup>
                    </div>
                }
                {/* Show display - article successfully edited */}
                {
                    (isEdited)
                        ?
                        <div>
                            <p style={{
                                color: 'darkgreen',
                                weight: 'bold'
                            }}>Article successfully edited!</p>
                            <Link to='/articles'>
                                <Button color='primary'>
                                    View
                                </Button>
                            </Link>
                        </div>
                        :
                        <div />
                }




                {/* Show delete page */}
                {
                    operation === 'delete'
                    &&
                    <CardGroup style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                            {
                                articles
                                    ?
                                    articles.map(article => (
                                        <div key={article.id}>
                                            <ArticleCard
                                                key={article.id}
                                                id={article.id}
                                                image={article.imageURL}
                                                title={article.title}
                                                author={article.author}
                                                date={article.date}
                                                content={article.content}
                                            />
                                            <Button color='primary' onClick={() => handleSelectForDeletion(article.id, article.title)}>Select</Button>
                                        </div>
                                    ))
                                    :
                                    <Loading />
                            }
                    </CardGroup>
                }
                {/* Show display - article successfully deleted */}
                {
                    (isDeleted)
                        ?
                        <div>
                            <p style={{
                                color: 'darkgreen',
                                weight: 'bold'
                            }}>Article successfully deleted!</p>
                            <Link to='/articles'>
                                <Button color='primary'>
                                    View Articles
                                </Button>
                            </Link>
                        </div>
                        :
                        <div />
                }

                {/* Loading component */}
                {
                    (isLoading)
                        ?
                        <Loading />
                        :
                        <div />
                }

                {/* Confirm delete article modal */}
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Are you sure you want to delete this article?</ModalHeader>
                    <ModalBody>
                        {modalTitle}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleDelete}>
                            Delete it!
                        </Button>
                        <Button color="primary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </CardBody>
            <CardFooter>
                <Link to={'/admin/'}>
                    <Button color='primary'>
                        Back to Admin
                    </Button>
                </Link>
            </CardFooter>
        </Card>
    );
}

export default AdminArticlesPage;
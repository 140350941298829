import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import {
    CardGroup,
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';

function ResourcesPage() {

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    });

    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Resources
                </CardTitle>
                <CardGroup style={{
                    marginTop: '5vh'
                }}>
                    <Link
                        to={'/blog'}
                        key={'blog'}
                    >
                        <Card>
                            <CardTitle>
                                Articles
                            </CardTitle>
                        </Card>
                    </Link>
                    <Link
                        to={'/media'}
                        key={'media'}
                    >
                        <Card>
                            <CardTitle>
                                Videos
                            </CardTitle>
                        </Card>
                    </Link>
                    <Link
                        to={'/courses'}
                        key={'courses'}
                    >
                        <Card>
                            <CardTitle>
                                Courses
                            </CardTitle>
                        </Card>
                    </Link>
                </CardGroup>
            </CardBody>
        </Card>
    );
}

export default ResourcesPage;
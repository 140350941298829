import React from 'react';
import {
    Spinner
} from 'reactstrap';

const Loading = () => {
    return (
        <div style={{
            margin: 'auto'
        }}>
            <Spinner color="success" />
            <p> Loading...</p>
        </div>
    )
}

export default Loading;




import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import {
    Form,
    FormGroup,
    Input,
    FormFeedback,
    Button,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Container
} from 'reactstrap';

// Contact form used to contact the business - sends to ???
function ContactPage() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [messageSent, setMessageSent] = useState(false);

    // State for form validation
    const [errors, setErrors] = useState({});

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
    });

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        // Validating first name field
        if (firstName.trim() === '') {
            newErrors.firstName = 'First name is required';
            valid = false;
        }

        // Validating last name field
        if (lastName.trim() === '') {
            newErrors.lastName = 'Last name is required';
            valid = false;
        }

        // Validating email field
        if (email.trim() === '') {
            newErrors.email = 'Valid email is required';
            valid = false;
        }

        // Validating message field
        if (message.trim() === '') {
            newErrors.message = 'A message is required';
            valid = false;
        }
        setErrors(newErrors);
        console.log(newErrors);
        return valid;
    };

    // Contact form submission
    function handleSubmit(event) {
        event.preventDefault();

        // Send form data to server
        if (validateForm()) {
            console.log('form is validated');

            fetch(`${process.env.REACT_APP_API_URL}api/v1/contact-form-submission`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    firstName,
                    lastName,
                    email,
                    message
                })
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Server response:', data);
                    setMessageSent(true);
                })
                .catch(error => {
                    console.error('Error sending message:', error);
                });
        }
        else {
            console.log(errors);
        }
    };

    function sendNewMessage() {
        setFirstName('');
        setLastName('');
        setEmail('');
        setMessage('');
        setMessageSent(false);
    };

    return (
        <Container>
            <Card style={{
                margin: '8vh auto',
                borderRadius: '25px'
            }}>
                <CardBody style={{ marginTop: '0' }}>
                    <CardTitle className='cursive-font' style={{
                        fontSize: '2.2em'
                    }}>
                        Contact Us
                    </CardTitle>

                    {
                        (messageSent)
                            ?
                            <>
                                <div>
                                    Thanks for your message {firstName}! We'll get back to you as soon as we can.
                                </div>
                                <Button color='success' onClick={sendNewMessage} style={{ margin: '5px' }}>Send another</Button>
                                <Link to='/home'>
                                    <Button color='primary' style={{ margin: '5px' }}>Go home</Button>
                                </Link>
                            </>
                            :

                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col>
                                        {errors.firstName && <FormFeedback>{errors.firstName}</FormFeedback>}
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name="firstName"
                                                placeholder="first name"
                                                onChange={e => setFirstName(e.target.value)}
                                                value={firstName}
                                                invalid={errors.firstName !== undefined}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        {errors.lastName && <FormFeedback>{errors.lastName}</FormFeedback>}
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name="lastName"
                                                placeholder="last name"
                                                onChange={e => setLastName(e.target.value)}
                                                value={lastName}
                                                invalid={errors.lastName !== undefined}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                                        <FormGroup>
                                            <Input
                                                type="email"
                                                name="email"
                                                placeholder="email"
                                                onChange={e => setEmail(e.target.value)}
                                                value={email}
                                                invalid={errors.email !== undefined}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {errors.message && <FormFeedback>{errors.message}</FormFeedback>}
                                        <FormGroup>
                                            <Input
                                                type="textarea"
                                                name="message"
                                                placeholder="write your message here"
                                                onChange={e => setMessage(e.target.value)}
                                                value={message}
                                                invalid={errors.message !== undefined}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <FormGroup style={{
                                        margin: 'auto',
                                    }}>
                                        <Button
                                            color='primary'
                                            style={{ margin: '5px' }}
                                            type='submit'
                                        >
                                            Submit
                                        </Button>                                        
                                    </FormGroup>
                                </Row>
                            </Form>
                    }
                </CardBody>
            </Card>
        </Container>
    );
}

export default ContactPage;
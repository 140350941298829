import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Table,
    Button
} from 'reactstrap';
import Loading from '../../components/Loading';

function AdminMessagesPage() {
    const [messages, setMessages] = useState([]);
    const [showRead, setShowRead] = useState(true);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })

        const shouldFetchMessages = !messages.length;

        if (shouldFetchMessages) {
            fetchMessages();
        }
    }, [messages]);

    async function fetchMessages() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/messages/`);

            if (!response.ok) {
                throw new Error(`Error ${response.status}: Network response was not ok`);
            }

            const data = await response.json();

            if (!Array.isArray(data) || data.length === 0) {
                throw new Error('No messages found');
            }

            const messages = data.map(message => {
                const date = new Date(message.date);
                const dateStr = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substr(-2)}`;
                const fullName = `${message.firstName} ${message.lastName}`;
                return { ...message, date: dateStr, fullName };
            });

            setMessages(messages);
        } catch (error) {
            console.error(error);
        }
    }

    async function handleRead(id) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/messages/read/${id}`, {
                method: 'PUT'
            });

            if (!response.ok) {
                throw new Error(`Error ${response.status}: Network response was not ok`);
            }

            const updatedMessages = messages.map(message => {
                if (message.id === id) {
                    return { ...message, read: !message.read };
                } else {
                    return message;
                }
            });

            setMessages(updatedMessages);
        } catch (error) {
            console.error(error);
        }
    }

    async function handleDelete(id) {
        try {
            const response = await fetch(`${ process.env.REACT_APP_API_URL }api/v1/admin/messages/delete/${id}`, {
                method: 'DELETE'
            });

            if (!response.ok) {
                throw new Error(`Error ${response.status}: Network response was not ok`);
            }

            const updatedMessages = messages.filter(message => message.id !== id);

            setMessages(updatedMessages);
        } catch (error) {
            console.error(error);
        }
    }

    const filteredMessages = showRead ? messages : messages.filter(message => !message.read);

    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',
        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Admin - Messages
                </CardTitle>

                <Table striped>
                    <thead>
                            <tr>
                                <th>Date</th>
                                <th colSpan="2">Name</th>
                                <th>Email</th>
                                <th>Message</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredMessages.length > 0 ? (
                                filteredMessages.map(message => (
                                    <tr key={message.id} style={{ fontWeight: message.read ? 'normal' : 'bold' }}>
                                        <td>{message.date}</td>
                                        <td colSpan="2">{message.fullName}</td>
                                        <td>{message.email}</td>
                                        <td>{message.message}</td>
                                        <td>
                                            {!message.read ? (
                                                <Button color='info' onClick={() => handleRead(message.id)}>Read</Button>
                                            ) : (
                                                    <>
                                                        <Button color='info' onClick={() => handleRead(message.id)}>Unread</Button>
                                                    <Button color='danger' onClick={() => handleDelete(message.id)}>Delete</Button>
                                                      </>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={6}>
                                        {messages.length > 0 ? (
                                            <p>No {showRead ? 'unread' : 'messages'} found.</p>
                                        ) : (
                                            <Loading />
                                        )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                </Table>
            </CardBody>
            <CardFooter>
                {
                    (showRead)
                        ?
                        <Button color='warning' onClick={() => setShowRead(false)}>
                            Show Unread
                        </Button>
                        :
                        <Button color='info' onClick={() => setShowRead(true)}>
                            Show All
                        </Button>
                }
                <Link to={'/admin/'}>
                    <Button color='primary' style={{
                        marginLeft: '5px'
                    }}>
                        Back to Admin
                    </Button>
                </Link>
            </CardFooter>
        </Card>
    );
}

export default AdminMessagesPage;

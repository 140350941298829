import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col
} from 'reactstrap';
import DonationForm from '../components/DonationForm';
import xIcon from '../assets/icons/x-icon.png';
import youtubeIcon from '../assets/icons/youtube-icon.png';
import linkedinIcon from '../assets/icons/linkedin-icon.png';
import facebookIcon from '../assets/icons/facebook-icon.png';
import instagramIcon from '../assets/icons/instagram-icon.png';
import pinterestIcon from '../assets/icons/pinterest-icon.png';
import rumbleIcon from '../assets/icons/rumble-icon.png';
import hypefuryAd from '../assets/images/hypefuryAd.png';

function SupportPage() {

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    });

    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Support the Cause
                </CardTitle>
                <Row>
                    <Col>
                        {/* Add nonce attribute to inline scripts */}
                        <a href='https://twitter.com/IgniteGnost' target='new'>
                            <img src={xIcon} alt='x icon' className='socialLink' />
                        </a>

                        <a href='https://www.facebook.com/profile.php?id=61555250354932' target='new'>
                            <img src={facebookIcon} alt='facebook icon' className='socialLink' />
                        </a>
                        <a href='https://www.instagram.com/ignitegnost/' target='new'>
                            <img src={instagramIcon} alt='instagram icon' className='socialLink' />
                        </a>
                        {/* <a href='https://www.pinterest.com/' target='new'>
                    <img src={pinterestIcon} alt='pinterest icon' className='socialLink' />
                </a> */}
                        {/* <a href='https://www.linkedin.com/' target='new'>
                    <img src={linkedinIcon} alt='linkedin icon' className='socialLink' />
                </a> */}
                        <a href='https://www.youtube.com/channel/UCrChkJKypQ2dQoXdrtsSsAg' target='new'>
                            <img src={youtubeIcon} alt='youtube icon' className='socialLink' />
                        </a>
                        <a href='https://rumble.com/c/c-5576289' target='new'>
                            <img src={rumbleIcon} alt='rumble icon' className='socialLink' />
                        </a>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <DonationForm />
                    </Col>
                </Row>
                <hr />
                {/* Google ads */}
                <Row>
                    <Col>
                        <div style={{
                            height: '60vh'
                        }}>
                            <a href='https://hypefury.com/?via=igniteyourgnost18' target='new'>
                                <img src={hypefuryAd} alt='an add for hypefury.com' style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%'
                                }} />
                            </a>
                        </div>
                    </Col>
                </Row>
                <hr />
            </CardBody>
        </Card>
    );
}

export default SupportPage;

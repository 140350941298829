import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle,
    CardText
} from 'reactstrap';

// Mailing List
function CareerPage() {

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
    });


    return (
        <Card
            className="shadow"
            style={{
                width: '80vw',
                minHeight: '60vh',
                borderRadius: '25px',
                margin: '8vh auto',
            }}
        >
            <CardBody style={{ marginTop: '0' }}>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Join the IgniteYourGnost Team!
                </CardTitle>
                <CardText>
                    At IgniteYourGnost, we're passionate about empowering individuals to lead healthier, more fulfilling lives.
                    Our commitment to wellness is at the heart of everything we do, and we're looking for talented individuals
                    who share our vision to join our dynamic team.
                </CardText>

                <br />

                <CardText style={{
                    fontSize: '1.2em'
                }}>
                    <b>Current Openings</b>
                </CardText>

                <br />

                <CardText>
                    <b>Video Editor</b>
                </CardText>
                <CardText>
                    We're seeking creative and skilled Video Editors to bring our health and wellness content to life.
                    If you have a knack for storytelling through visuals and a keen eye for detail, we want you on our team!
                </CardText>

                <br />

                <CardText>
                    <b>Digital Marketing Associate</b>
                </CardText>
                <CardText>
                    Join us as a Digital Marketing Associate and help spread the word about our life-changing wellness programs.
                    Ideal candidates should have experience in SEO, PPC, and social media marketing, with a passion for health and wellness.
                </CardText>

                <br />

                <CardText>
                    <b>Social Media Manager</b>
                </CardText>
                <CardText>
                    Are you a social media wizard with a love for wellness? As our Social Media Manager, you'll be the voice of IgniteYourGnost online,
                    engaging with our community and driving our brand message across various platforms.
                </CardText>

                <br />

                <CardText>
                    <b>Sales</b>
                </CardText>
                <CardText>
                    We're looking for dynamic Sales Professionals to join our team.
                    If you have a talent for communication, a persuasive touch, and a drive to succeed, you could be the
                    perfect fit to help us expand our reach and impact.
                </CardText>

                <br />

                <CardText>
                    <b>Why Work With Us?</b>
                </CardText>
                <CardText>
                    <CardText>
                        <b>Innovative Environment:</b> Work at the cutting edge of health and wellness.
                    </CardText>
                    <CardText>
                        <b>Team Spirit:</b> Join a supportive team that values collaboration and innovation.
                    </CardText>
                    <CardText>
                        <b>Growth Opportunities:</b> We believe in nurturing talent and offer ample opportunities
                    </CardText>
                    <CardText>
                        <b>Impactful Work:</b> Make a real difference in people's lives every day.
                    </CardText>
                </CardText>

                <br />

                <CardText>
                    <b>Apply Now</b>
                </CardText>
                <CardText>
                    To apply, click the button below, enter your name, email, and the role you're interested in and we'll get in touch.
                </CardText>
                <Link className='uppercase-font' to='/contact'>
                    <div style={{
                        marginTop: '3vh',
                        marginBottom: '1vh',
                        backgroundColor: 'blue',
                        color: 'white',
                        paddingTop: '1.5vh',
                        paddingBottom: '1vh',
                        borderRadius: '25px'
                    }}>
                        I'm ready to apply!
                    </div>
                </Link>
            </CardBody>
        </Card >
    );
}

export default CareerPage;
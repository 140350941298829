import React, {useEffect} from 'react';
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import Video from '../components/Video'
import {
    Card,
    CardBody,
    CardTitle,
    CardText
} from 'reactstrap';


function AboutPage() {

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    });

    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    About IgniteYourGnost
                </CardTitle>
                <div style={{
                    whiteSpace: 'pre-wrap',
                    textAlign: 'left',
                    marginTop: '5vh',
                    fontSize: '0.9em'
                }}>
                    {/* About us text */}
                    <h2 style={{
                        textAlign: 'center'
                    }}>A Journey Towards Spiritual Healing and Transformation</h2>
                    <CardText>
                        Welcome to IgniteYourGnost, your sanctuary for healing and spiritual growth. 
                        Founded by a team of Usui Reiki Masters, our mission is to help you unlock your inner wisdom and peace. 
                        We offer a variety of content, courses, and services related to Reiki, mindfulness, meditation,
                        and spirituality to aid you on your path to enlightenment.

                        Join IgniteYourGnost today and embark on a transformative journey to attain peace, fulfillment, and well-being. 

                        IgniteYourGnost, where health meets wisdow!
                    </CardText>
                    <br />
                    <Video
                        embedId='_shmjR8Y8YU'
                    />   
                    <br />
                    <h2 style={{
                        textAlign: 'center'
                    }}>What We Offer</h2>
                    <CardText>
                        IgniteYourGnost offers a variety of resources for spiritual well-being, including insightful <Link to='/articles'>articles</Link>, personalized Reiki sessions,
                        comprehensive online courses, and a curated selection of products like crystals and chakra bracelets, all designed to educate, inspire, and
                        support you on your path to self-improvement and holistic healing.
                    </CardText>
                    <br/>
                    <h2 style={{
                        textAlign: 'center'
                    }}>Connect With Us</h2>
                    <CardText>
                        Stay updated with the latest insights, tips, and news from IgniteYourGnost by following us on X <a href='https://www.twitter.com/ignitegnost' target='new'>@ignitegnost</a>.
                        We're always eager to connect with our growing community of spiritual seekers and share our wisdom.
                    </CardText>
                    <br />
                    <h2 style={{
                        textAlign: 'center'
                    }}>Join Us Today:</h2>
                    <CardText>
                        Embark on a transformative journey with IgniteYourGnost and experience the life-changing benefits of holistic healing.
                        Together, we'll unlock your innate wisdom, restore balance, and reignite your inner light.
                    </CardText> 
                    <br />
                    <CardText>
                        Ignite Your Wellness, IgniteYourGnost!
                    </CardText>
                </div>
            </CardBody>
        </Card>

    );
}

export default AboutPage;
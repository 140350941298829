import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle,
    CardText
} from 'reactstrap';

// Mailing List
function NewsletterPage() {

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
    });


    return (
            <Card
                className="shadow"
                style={{
                    width: '80vw',
                    minHeight: '60vh',
                    borderRadius: '25px',
                    margin: '8vh auto',
                }}
            >
                <CardBody style={{ marginTop: '0' }}>
                    <CardTitle className='cursive-font' style={{
                        fontSize: '2.2em'
                    }}>
                        Join the IgniteYourGnost Mailing List!
                </CardTitle>               
                <CardText style={{
                    marginTop: '5vh',
                    fontSize: '1.2em'
                }}>
                    🌟 Embark on a journey of rejuvenation and empowerment with IgniteYourGnost🌟 
                </CardText>

                <br />
                
                <CardText className='text-left' style={{
                    fontSize: '1em'
                }}>
                        Our expertise in Reiki healing and a spectrum of health and wellness services is just the beginning. As a valued member of our community, you'll gain exclusive access to:
                    </CardText>
                <CardText className='text-left' style={{
                    marginLeft: '3vw',
                    fontSize: '0.9em'
                }}>
                        🌈 Insightful Reiki Secrets: Unlock the ancient wisdom of Reiki, tailored for modern living.
                    </CardText>
                <CardText className='text-left' style={{
                    marginLeft: '3vw',
                    fontSize: '0.9em'
                }}>
                        💡 Wellness Tips & Tricks: Regular insights into wellness practices that can transform your daily life.
                    </CardText>
                <CardText className='text-left' style={{
                    marginLeft: '3vw',
                    fontSize: '0.9em'
                }}>
                        📘 Original Content: Be the first to receive our meticulously crafted articles, guides, and more.
                    </CardText>
                <CardText className='text-left' style={{
                    marginLeft: '3vw',
                    fontSize: '0.9em'
                }}>
                        🎉 Special Offers & Discounts: Enjoy members-only offers on our range of services.
                    </CardText>
                <CardText className='text-left' style={{
                    marginLeft: '3vw',
                    fontSize: '0.9em'
                }}>
                        💌 Personalized Advice: Get wellness tips directly to your inbox.
                </CardText>

                <br />
                
                <CardText style={{
                    fontSize: '1.2em'
                }}>
                        🔥 Ignite Your Journey Towards Wellness 🔥
                </CardText>
                
                <br />
                
                <CardText className='text-left'>
                        Subscribe to our mailing list today and be part of a community that's passionate about holistic health and personal growth. No spam, just pure wellness gold!
                </CardText>
                <Link className='uppercase-font' to='/newsletter/signup'>
                    <div style={{
                        marginTop: '3vh',
                        marginBottom: '1vh',
                        backgroundColor: 'red',
                        paddingTop: '1.5vh',
                        paddingBottom: '1vh',
                        borderRadius: '25px'
                    }}>
                        I'm ready to sign up!
                    </div>
                </Link>
                </CardBody>
            </Card >
    );
}

export default NewsletterPage;
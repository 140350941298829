import React, { useState, useEffect } from 'react';
import AuthContext from '../contexts/AuthContext';

const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);

    // You could load the initial authentication state from storage (e.g., localStorage)
    useEffect(() => {
        // loadInitialAuthState();
    }, []);

    const login = (email, password) => {
        // Perform the login logic (e.g., making an API call)
        setIsLoggedIn(true);
        setUser({ email });
    };

    const logout = () => {
        // Perform the logout logic (e.g., making an API call, clearing the storage)
        setIsLoggedIn(false);
        setUser(null);
    };

    const authContextValue = {
        isLoggedIn,
        user,
        login,
        logout,
    };

    return (
        <AuthContext.Provider value={authContextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

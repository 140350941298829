import React from 'react';

const VideoComponent = (props) => {
    return (
        <div style={{
            width: '100 %',
            height: '0',
            paddingBottom: '56.25%', /* 16:9 aspect ratio, adjust as needed */
            position: 'relative',
            overflow: 'hidden'
        }}>
            <iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${props.embedId}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%'

                }}
            />
        </div>
    );
}

export default VideoComponent;
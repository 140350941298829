import React from 'react';
import ReactGA from "react-ga4";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from './components/Header';
import page from './pages';
import Footer from './components/Footer';
import { useAuth } from './contexts/AuthContext';
import Login from './pages/Login';

const stripePromise = loadStripe('pk_live_51OStZbJX02qzvtaPXk8PDkHVss43Cp0mhmONua8oPSskg87bAazeHPqLC7zUych8fVRvTCMpTB0RUFUDF9qQ2wVF00ZdJx0QkF');

// Google Analytics
ReactGA.initialize('G-Z6PH105Z63');

const App = () => {
  const { currentUser } = useAuth();

  return (
    <Elements stripe={stripePromise}>
      <div className='App' style={{
        backgroundColor: '#C5F4E0'
      }}>
        <Router>
          <Header />
          <div style={{
            minHeight: '68vh'
          }}>
            <Switch>
              <Route path='/about' component={page.About} />
              <Route path='/accessibility' component={page.Accessibility} />
              <Route path='/account' component={page.Account} />
              <Route path='/articles' component={page.Articles} />
              <Route path='/article-details' component={page.ArticleDetails} />
              <Route path='/cart' component={page.Cart} />
              <Route path='/careers' component={page.Careers} />
              <Route path='/checkout' component={page.Checkout} />
              <Route path='/comingsoon' component={page.ComingSoon} />
              <Route path='/contact' component={page.Contact} />
              <Route path='/courses' component={page.Courses} />
              <Route path='/disclaimer' component={page.Disclaimer} />
              <Route path='/home' component={page.Home} />
              <Route path='/newsletter/signup' component={page.NewsletterSignUp} />
              <Route path='/newsletter' component={page.NewsletterFunnel} />
              <Route path='/media' component={page.Media} />
              <Route path='/privacy-policy' component={page.Policy} />
              <Route path='/product-details' component={page.ProductDetails} />
              <Route path='/receipt' component={page.Receipt} />
              <Route path='/resources' component={page.Resources} />
              <Route path='/store' component={page.Store} />
              <Route path='/support' component={page.Support} />
              <Route path='/help' component={page.Help} />
              <Route path='/terms-of-use' component={page.Terms} />
              <Route path='/admin/articles' component={page.AdminArticles} />
              <Route path='/admin/mlcontacts' component={page.AdminMailingList} />
              <Route path='/admin/messages' component={page.AdminMessages} />
              <Route path='/admin/users' component={page.AdminUsers} />
              <Route path='/admin' component={page.Admin} />
              <Route path='/login'>
                {/* Pass the location prop to the Login component */}
                {({ location }) => (
                  // If the user is logged in, redirect to the home page; otherwise, render the Login page
                  currentUser ? <Redirect to="/home" /> : <Login location={location} />
                )}
              </Route>
              <Route path='/signup'>
                {/* If the user is logged in, redirect to the home page; otherwise, render the Login page */}
                {currentUser ? <Redirect to="/home" /> : <page.Signup />}
              </Route>
              {/* <Route exact path='/admin'> */}
              {/* If the user is not logged in, redirect to the login page; otherwise, render the Admin page */}
              {/* {!currentUser ? <Redirect to="/login" /> : <page.Admin />} */}
              {/* </Route> */}
              <Route path='*' component={page.Home} />
            </Switch>
          </div>
          <Footer />
        </Router>
      </div>
    </Elements>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import {
    Card,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col
} from 'reactstrap';
import Loading from '../components/Loading';

function ProductDetailsPage() {

    const [product, setProduct] = useState("");
    const [quantity, setQuantity] = useState(1);


    useEffect(() => {

        // Google Analytics
            ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })

        if (product === "") {

            const pathname = window.location.pathname;

            const productID = pathname.split('/').pop()

            fetch(
                '/api/v1/products/' + productID,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': ' application/json'
                    },
                })
                .then(res => res.json()).then(data => {
                    setProduct(data);
                })
                .catch(err => {
                    console.error('Error: ' + err)
                });
        }
    });

    function addToCart() {

        // if cart is found in local storage
        if (localStorage.cart) {
            // retrieve cart
            const oldjson = localStorage.getItem('cart');
            // convert to object format
            let oldCart = JSON.parse(oldjson);
            // remove array, exposing object
            let tempCart = (oldCart[0]);

            // clear local storage
            localStorage.removeItem("cart");

            ///////////////////////////////////////////////
            // This is not working

            // check if product is already in cart
            console.log(tempCart.id + ' ' + product.id)
            if (tempCart.id === product.id) {
                // if so, increase quantity of product
                let newProduct = product
                newProduct.qty = product.qty + quantity
                console.log('product qty = ' + product.qty)
                console.log('new qty = ' + newProduct.qty)
                console.log('quantity increased by ' + quantity)
            }
            // if product is not already in cart
            else {
                // add selected quantity to product
                let newProduct = product;
                newProduct.qty = quantity;
                // add new product to cart
                const newCart = oldCart.concat(newProduct);
                let newjson = JSON.stringify(newCart);
                localStorage.setItem("cart", newjson);
            }
        }
        // no cart, create one and add product
        else {
            let newCart = [];
            let newProduct = product;
            newProduct.qty = quantity;
            newCart.push(newProduct);
            let newjson = JSON.stringify(newCart);
            localStorage.setItem("cart", newjson);
        }
    }

    function buyNow() {
        console.log('buy now')
        // goes straight to checkout
    }

    function clearStorage() {
        localStorage.clear()
        console.log('local storage has been cleared ' + localStorage)
    }

    return (

        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Product Details
                </CardTitle>
                {(product)
                    ?
                    <Row>
                        <Col>
                            <img src={product.images[0]} alt={'a picture of ' + product.name} />
                        </Col>
                        <Col>
                            <h3>{product.name}</h3>
                            <h5>{product.description}</h5>
                            <h5>{'$' + product.cost}</h5>
                        </Col>
                        <Col>
                            <div>
                                <h4>Quantity: {quantity}</h4>
                                <Button
                                    color='primary'
                                    onClick={() => {
                                        if (quantity >= 1) {
                                            setQuantity(quantity + 1)
                                        }
                                    }}>
                                    +
                                </Button>
                                <Button
                                    color='danger'
                                    onClick={() => {
                                        if (quantity > 1) {
                                            setQuantity(quantity - 1)
                                        }
                                    }}>
                                    -
                                </Button>
                            </div>
                            <Button color='info' onClick={addToCart}>
                                Add to Cart
                            </Button>
                            <Button color='success' onClick={buyNow}>
                                Buy Now
                            </Button>
                            <Button onClick={clearStorage}>Clear</Button>
                        </Col>
                    </Row>
                    :
                    <Loading />

                }

            </CardBody>
        </Card>

    );
}

export default ProductDetailsPage;
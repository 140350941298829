import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import {
    Container,
    Row,
    Col,
    Card,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
} from "reactstrap";
import "./Pages.css";
import Loading from "../components/Loading";
import stones from "../assets/images/stones.jpg";

function HomePage() {
    const [isLoading, setIsLoading] = useState(true);

    // Google Analytics
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: window.location.pathname,
        });
    });

    return (
        <Container
            fluid="true"
            style={{
                padding: "0",
                margin: "0",
            }}
        >
            {isLoading && <Loading />} {/* show the loading component */}
            <Row>
                <Col
                    style={{
                        postition: "relative",
                        padding: "0",
                        visibility: isLoading ? "hidden" : "visible", // hide the content while the image is loading
                    }}
                >
                    <Card>
                        <CardImg
                            onLoad={() => setIsLoading(false)} // hide the loading component once the image is loaded
                            src={stones}
                            alt="A green-hills on a beach with the sunset over the ocean in the background."
                            style={{
                                height: "90vh",
                                filter: "brightness(85%)",
                                objectFit: "cover",
                            }}
                        />
                    </Card>
                    <Card
                        className="hover-card shadow"
                        style={{
                            margin: "0 18vw",
                            borderRadius: "25px",
                            position: "absolute",
                            top: "20%",
                            left: "2vw",
                            padding: "5%",
                            zIndex: "5",
                            opacity: "95%",
                        }}
                    >
                        <CardTitle
                            className="cursive-font"
                            style={{
                                fontSize: "calc(10px + 5vmin)",
                            }}
                        >
                            Align With Your Highest Self
                        </CardTitle>
                        <CardSubtitle className="uppercase-font">
                            Transform your body, mind, and spirit with energy healing and
                            natural medicine
                        </CardSubtitle>
                        <CardText>
                            Harmonize your natural energy with self-centering practices that
                            enable you to reach your full potential
                        </CardText>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default HomePage;

import { createContext, useContext, useState } from 'react';

// Create an initial context object with default values
const AuthContext = createContext({
    currentUser: null,
    login: () => { },
    logout: () => { },
});

// Create a custom hook to use the AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};

// Create an AuthProvider component to wrap the app and provide the context
export const AuthProvider = ({ children }) => {
    // Set up the state for the currentUser
    const [currentUser, setCurrentUser] = useState(null);

    // Create a login function to update the currentUser state and set the token in local storage
    const login = (token) => {
        localStorage.setItem('token', token);
        setCurrentUser(token);
        console.log("User has been updated in context")
    };

    // Create a logout function to remove the token from local storage and update the currentUser state
    const logout = () => {
        localStorage.removeItem('token');
        setCurrentUser(null);
    };

    // Render the AuthContext.Provider component with the currentUser state and login/logout functions as the context value
    return (
        <AuthContext.Provider value={{ currentUser, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
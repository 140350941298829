import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import {
    Card,
    CardImg,
} from 'reactstrap';
import comingsoon from '../assets/images/coming-soon.jpg';


function ComingSoonPage() {


    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    });

    return (
        <Card style={{
            margin: '6vh 6vw',
            borderRadius: '25px'
        }}>
            <CardImg src={comingsoon} alt='Coming Soon' style={{
                maxHeight: '60vh',
                borderRadius: '25px'
            }} />
        </Card>
    );
}

export default ComingSoonPage;
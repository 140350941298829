import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';

function HelpPage() {

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    });

    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Help & FAQ
                </CardTitle>
                <br />
                <h3>Having Issues?</h3>
                <p><Link to='/contact'>Contact Us</Link></p>
                <br />
                <br />
                <h3>Frequently Asked Questions:</h3>
                <p>Coming Soon!</p>
            </CardBody>
        </Card>
    );
}

export default HelpPage;
import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';

const DonationForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [donationAmount, setDonationAmount] = useState(''); // Add state to handle donation amount

    const handleSubmit = async (event) => {
        event.preventDefault();
        const cardElement = elements.getElement(CardElement);

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // Call your backend to create the PaymentIntent
        const response = await fetch('/create-payment-intent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ amount: donationAmount }), // Send the donation amount to your backend
        });

        const paymentIntentResponse = await response.json();

        // Confirm the payment
        const result = await stripe.confirmCardPayment(paymentIntentResponse.clientSecret, {
            payment_method: {
                card: cardElement,
            },
        });

        if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
        } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer
                console.log("Donation successful!");
            }
        }
    };

    return (
        <Container fluid style={{
            marginTop: '25px'
        }}>
            <Row>
                <Col xs='0' md='3' lg='4'></Col>
                <Col xs='12' md='6' lg='4'>
                    <h3>Donate</h3>
                    <form onSubmit={handleSubmit}>
                        <div style={{
                            display: 'flex',  // Use flexbox to align items in a row
                            alignItems: 'center', // Align items vertically in the center
                            border: '1px solid #ccc', // Optional: add a border to the container
                            padding: '5px', // Padding inside the container
                            borderRadius: '4px' // Optional: round the corners of the container
                        }}>
                            <span style={{
                                marginRight: '5px', // Space between $ and input field
                                fontSize: '16px', // Match the font size with the input field
                            }}>
                                $
                            </span>
                            <input
                                type="number"
                                value={donationAmount}
                                onChange={(e) => setDonationAmount(e.target.value)}
                                placeholder="10"
                                style={{
                                    border: 'none', // Remove border from input
                                    outline: 'none', // Remove focus outline
                                    fontSize: '16px', // Matching font size
                                    width: '100%', // Input field takes up remaining space
                                }}
                            />
                        </div>                     
                        <div style={{
                            marginTop: '25px',
                            marginBottom: '20px'
                        }}>
                            <CardElement />
                        </div>
                        <Button color="primary" type="submit" disabled={!stripe}>
                            Donate
                        </Button>
                    </form>
                </Col>
                <Col xs='0' md='3' lg='4'></Col>
            </Row>
        </Container >
    );
};

export default DonationForm;

import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import {
    Row,
    Col
} from 'reactstrap';
import logo from '../assets/icons/KSlogo.svg';
import AuthContext from '../contexts/AuthContext';

export default function HeaderComponent() {

    const { isLoggedIn, logout } = useContext(AuthContext);

    return (
        <Row id='header' style={{
            backgroundColor: 'whitesmoke',
            width: '100vw'
        }}>
            <Link to='/home'>
                <Col sm='3' style={{
                    textAlign: 'left',
                    display: 'flex'
                }}>
                    <img src={logo} alt="IgniteYourGnost's logo" style={{
                        maxHeight: '9vh'
                    }} />
                    <div style={{
                        textAlign: 'center'
                    }}>
                        <h3 className='cursive-font' style={{
                            fontSize: '1.8em',
                        }}>IgniteYourGnost</h3>
                        <h5 className='uppercase-font'>- Health and Wellness -</h5>
                    </div>
                </Col>
            </Link>
            <Col sm='6'>
                <div style={{
                    marginTop: '2.5%'
                }}>
                    {/* <Link className='navLink uppercase-font' to='/admin'>Admin</Link> */}
                    <Link className='navLink uppercase-font' to='/about'>About</Link>
                    <Link className='navLink uppercase-font' to='/articles'>Articles</Link>
                    {/* <Link className='navLink uppercase-font' to='/resources'>Resources</Link> */}
                    {/* <Link className='navLink uppercase-font' to='/courses'>Courses</Link> */}
                    <Link className='navLink uppercase-font' to='/media'>Videos</Link>
                    {/* <Link className='navLink uppercase-font' to='/store'>Store</Link> */}
                    <a href='https://ignitegnost.gumroad.com/' target='new' className='navLink uppercase-font'>Store</a>
                    <Link className='navLink uppercase-font' to='/support'>Support</Link>
                </div>
            </Col>
            <Col sm='3'>
                <div style={{
                    marginTop: '5%'
                }}>

                    {
                        (isLoggedIn)
                            ?
                            <>
                                <Link className='navLink uppercase-font' to='/logout' onClick={logout}>Log Out</Link>
                                <Link className='navLink uppercase-font' to='/account'>My Account</Link>
                            </>
                            :
                            <Link className='navLink uppercase-font' to='/login'>Log In</Link>
                    }
                    {/* <Link className='navLink uppercase-font' to='/cart'>View Cart</Link> */}
                </div>
            </Col>
        </Row >

    );
}

import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import {
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Button
} from 'reactstrap';
import { Link } from "react-router-dom";
import CartItem from '../components/CartItem';
import Loading from '../components/Loading';

function CartPage() {

    const [items, setItems] = useState("");

    useEffect(() => {
// Google Analytics
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })

        if (localStorage.getItem('cart') && items === "") {
            let storedItems = localStorage.getItem('cart');
            let newItems = storedItems.split('}');
            setItems(newItems);
        }
    }, [items]);




    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Cart
                </CardTitle>
                    
                    {
                    // this isn't working properly
                        (items)
                            ?
                            (items).map((item, key) => (
                                <CartItem
                                    key={key}
                                    id={item.id}
                                    image={item.images}
                                    name={item.name}
                                    cost={item.cost}
                                    quantity={item.quantity}
                                />
                            ))
                            :
                            < Loading />
                    }
            </CardBody>
            <CardFooter>
                <Link to={'/checkout'}>
                    <Button color='success'>
                        Checkout
                    </Button>
                    <Button color='primary'>
                        Keep Shopping
                    </Button>
                </Link>
            </CardFooter>
        </Card>
    );
}

export default CartPage;
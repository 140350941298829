import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import {
    CardGroup,
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';
import ProductCard from '../components/ProductCard';
import Loading from '../components/Loading';

function StorePage() {
    const [products, setProducts] = useState("");

    useEffect(() => {
        // Google Analytics
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });

        // Fetching products from server
        if (products === "") {
            fetch(
                '/api/v1/products/all',
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': ' application/json'
                    },
                })
                .then(res => res.json()).then(data => {
                    // setProducts(data);
                })
                .catch(err => {
                    console.error('Error: ' + err)
                });
        }
    });

    return (
        <div>
            <Card className='shadow' style={{
                width: '80vw',
                minHeight: '60vh',
                borderRadius: '25px',
                margin: '8vh auto',

            }}>
                <CardBody>
                    <CardTitle className='cursive-font' style={{
                        fontSize: '2.2em'
                    }}>
                        Store
                    </CardTitle>
                    <CardGroup style={{
                        marginTop: '5vh',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        {
                            (products)
                                ?
                                (products).map((item, key) => (
                                    <Link
                                        to={'/product-details/' + item.id}
                                        key={key}
                                    >
                                        <ProductCard
                                            key={key}
                                            id={item.id}
                                            image={item.images}
                                            name={item.name}
                                            cost={item.cost}
                                        />
                                    </Link>
                                ))
                                :
                                <Loading />
                        }
                    </CardGroup>
                </CardBody>
            </Card>
        </div>
    );
}

export default StorePage;
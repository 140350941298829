import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    // Row,
    // Col,
    // FormGroup,
    // Form,
    // Input,
    Table,
    Button
} from 'reactstrap';
import Loading from '../../components/Loading'; 

function AdminMailingListPage() {

    const [users, setUsers] = useState('');

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    
        // If the users state is empty, fetch the users
        const shouldFetchUsers = !users;

        if (shouldFetchUsers) {
            fetchUsers();
        }
    }, [users]);

    // Fetching mailing list contacts from server
    async function fetchUsers() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/mlcontacts/`);

            if (!response.ok) {
                throw new Error(`Error ${response.status}: Network response was not ok`);
            }

            const data = await response.json();

            if (!Array.isArray(data) || data.length === 0) {
                throw new Error('No mailing list contacts found');
            }

            const users = data.map(user => {
                const date = new Date(user.date);
                const dateStr = date.toLocaleDateString(undefined, { dateStyle: 'medium' });
                return { ...user, date: dateStr };
            });

            setUsers(users);
        } catch (error) {
            console.error(error);
            // setErrorMessage(error.message);
        }
    }

    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Admin - Mailing List
                </CardTitle>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users ? (
                            users.map(user => (
                                <tr key={user.id}>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={6}>
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </CardBody>
            <CardFooter>
                <Link to={'/admin/'}>
                    <Button color='primary'>
                        Back to Admin
                    </Button>
                </Link>
            </CardFooter>
        </Card>
    );
}

export default AdminMailingListPage;
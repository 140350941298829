import React from 'react';
import {
    Container,
    Row,
    Col
} from 'reactstrap';

const ArticleCard = (props) => {
    return (
        <Container fluid style={{
            width:'70vw'
        }}>
            <Row>
                <Col xs='12' sm='6' md='3'>
                    <img
                        alt={props.title}
                        src={props.image}
                        style={{
                            height: 'auto',  // Set height to 'auto'
                            maxWidth: '100%',  // Set maximum width to 100% to fit container
                            maxHeight: '25vh'
                        }}
                    />
                </Col>
                <Col xs='12' sm='6' md='9'>
                    < div style={{
                        textAlign: 'left'
                    }}>
                        {props.title}
                    </div>
                </Col>
            </Row>
            <hr/>
        </Container>
    )
}

export default ArticleCard;
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import {
    Card,
    CardBody,
    CardTitle,
    Button,
} from 'reactstrap';

function AdminPage() {

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })

    });

    
    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Admin
                </CardTitle>

                {/* Go to Mailing List Page */}
                <Link to={'/admin/mlcontacts'}>
                    <Button color='primary'>
                        Manage Mailing List
                    </Button>
                </Link>

                <br />

                {/* Go to Admin Messages Page */}
                <Link to={'/admin/messages'}>
                    <Button color='primary'>
                        Manage Messages
                    </Button>
                </Link>

                <br/>

                {/* Go to Admin Users Page */}
                <Link to={'/admin/users'}>
                    <Button color='primary'>
                        Manage Users
                    </Button>
                </Link>

                <div>
                    {/* Go to Admin Articles Pages */}
                    {/* Add new article */}
                    <Link to={'/admin/articles/new'}>
                        <Button color='info'>
                            Add New Article
                        </Button>
                    </Link>

                    {/* Edit article */}
                    <Link to={'/admin/articles/edit'}>
                        <Button color='warning'>
                            Edit Article
                        </Button>
                    </Link>

                    {/* Delete article */}
                    <Link to={'/admin/articles/delete'}>
                        <Button color='danger'>
                           Delete Article
                        </Button>
                    </Link>
                </div>
                
            </CardBody>
        </Card >
    );
}

export default AdminPage;
import React from 'react';
import {
    Card,
    CardImg,
    CardBody,
    CardSubtitle,
    CardTitle,
} from 'reactstrap';

const ProductCard = (props) => {
    return (
        <Card className='product-card' style={{
            borderRadius: '25px',
            margin: '0vh 0.5vw 1vh 0.5vw',
            minWidth: '250px',
            maxWidth: '250px',
            maxHeight: '95%',
        }}>
            <CardImg src={props.image} alt={props.description} style={{
                borderRadius: '25px 25px 0px 0px',
                height: '25vh',
                maxHeight: '25vh'
            }} />
            <CardBody>
                {<CardTitle style={{ display: 'inline' }}>{props.name}</CardTitle>}
                {<CardSubtitle>{'$' + props.cost}</CardSubtitle>}
            </CardBody>
        </Card>
    )
}

export default ProductCard;
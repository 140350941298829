import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import {
    Card,
    CardBody,
    CardTitle,
    CardText
} from 'reactstrap';

function AccessibilityPage() {

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    });

    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
            <CardTitle className='cursive-font' style={{
                fontSize: '2.2em'
            }}>
                Accessibility
            </CardTitle>
                <CardText>
                    <p>At IgniteYourGnost.com, we are committed to making our website accessible to all users, including those with disabilities. We strive to meet or exceed the Web Content Accessibility Guidelines (WCAG) 2.1 Level AA standards and other applicable laws and regulations.</p>

                    <h3>Accessibility Features</h3>
                    <p>We have implemented the following accessibility features on our website:</p>
                    <ul>
                    <li>Alternative text for images and other non-text content</li>
                        <li>Keyboard navigation and focus indicators for users who cannot use a mouse</li>
                        <li>Descriptive and meaningful hyperlink text</li>
                        <li>Consistent and predictable navigation and layout</li>
                        <li>Closed captions and transcripts for videos</li>
                        <li>A high contrast mode for users with low vision</li>
                        <li>Compatibility with screen readers and other assistive technologies</li>
                    </ul>

                    <h3>Ongoing Efforts</h3>
                    <p>We are continuously working to improve the accessibility of our website. Our team regularly reviews our website for accessibility issues and updates our accessibility features as needed. We also welcome feedback from users about any accessibility issues they may encounter.</p>

                    <h3>Contact Us</h3>
                    <p>If you have any questions or concerns about the accessibility of our website or if you encounter any accessibility barriers, please contact us at ignitegnost@gmail.com. We will do our best to address your concerns and improve your user experience.</p>

                    <p>Thank you for using IgniteYourGnost.com. We are committed to making our website accessible to all users and welcome your feedback on how we can improve our accessibility.</p>
</CardText>
            </CardBody>
        </Card>
    );
}

export default AccessibilityPage;
import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import {
    Card,
    CardBody,
    CardTitle,
    CardText
} from 'reactstrap';

function TermsPage() {

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    });

    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Terms of Service
                </CardTitle>
                <CardText>
                    <p>Welcome to IgniteYourGnost.com, an online platform for health and wellness education focusing on Reiki. By using our website, you agree to the following Terms of Service ("Terms"). Please read these Terms carefully before using our website. If you do not agree with these Terms, please do not use our website.</p>
                    
                    <h3>Acceptance of Terms</h3>
                    <p>By accessing or using our website, you agree to be bound by these Terms, which may be updated from time to time at our sole discretion. Your continued use of our website after the posting of any changes to these Terms constitutes your acceptance of those changes.</p>

                    <h3>Use of Our Website</h3>
                    <p>You are authorized to access and use our website for its intended purposes. You agree to use our website in compliance with all applicable laws and regulations. You may not use our website for any illegal or unauthorized purpose.</p>

                    <h3>User Accounts</h3>
                    <p>In order to use certain features of our website, you may need to create a user account. You are solely responsible for the security of your user account and all activities that occur under your account. You agree to keep your user account information up-to-date and to promptly notify us of any unauthorized use or breach of your account.</p>

                    <h3>Intellectual Property</h3>
                    <p>All content on our website, including but not limited to text, graphics, logos, images, and software, is the property of IgniteYourGnost.com or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use our content without our prior written consent.</p>

                    <h3>Limitations of Liability</h3>
                    <p>IgniteYourGnost.com shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our website. We make no warranties or representations about the accuracy or completeness of the content on our website or the suitability or quality of the products or services offered on our website.</p>

                    <h3>Indemnification</h3>
                    <p>You agree to indemnify and hold harmless IgniteYourGnost.com, its affiliates, officers, directors, employees, and agents from any claims, damages, liabilities, and expenses (including attorneys' fees) arising out of or in connection with your use of our website.</p>

                    <h3>Governing Law</h3>
                    <p>These Terms shall be governed by and construed in accordance with the laws of the United States, without giving effect to any principles of conflicts of law.</p>

                    <h3>Termination</h3>
                    <p>We reserve the right to terminate your access to our website at any time, for any reason, without notice.</p>

                    <h3>Miscellaneous</h3>
                    <p>These Terms constitute the entire agreement between you and IgniteYourGnost.com regarding your use of our website. If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect. Our failure to enforce any right or provision of these Terms shall not be deemed a waiver of such right or provision.</p>

                    <p>Thank you for using IgniteYourGnost.com. If you have any questions about these Terms, please contact us at ignitegnost@gmail.com.</p>

                </CardText>
            </CardBody>
        </Card>
    );
}

export default TermsPage;
import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import {
    CardGroup,
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';
import CourseCard from '../components/CourseCard';
import Loading from '../components/Loading';

function CoursesPage() {
    const [courses, setCourses] = useState("");

    useEffect(() => {
        // Google Analytics

            ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })

        fetch(
            '/api/v1/courses/all',
            {
                method: 'GET',
                // headers: new Headers({
                //     Accept: ?
                // })
            }
        )
            .then(res => {
                console.log(res)
                setCourses(res.data)
            })
    });


    return (
        <div>
            <Card className='shadow' style={{
                width: '80vw',
                height: '60vh',
                borderRadius: '25px',
                margin: '8vh auto',

            }}>
                <CardBody>
                    <CardTitle className='cursive-font' style={{
                        fontSize: '2.2em'
                    }}>
                        Courses
                    </CardTitle>
                    <CardGroup style={{
                        marginTop: '5vh'
                    }}>
                        {
                            (courses)
                                ?
                                (courses).map((item, key) => (
                                    <CourseCard
                                        key={key}
                                        id={item.id}
                                        image={item.images}
                                        name={item.name}
                                        cost={item.cost}
                                        onImgClick={() => this.courseDetails(item.id)}
                                    />
                                ))
                                :
                                <Loading />
                        }
                    </CardGroup>
                </CardBody>
            </Card>
        </div>
    );
}

export default CoursesPage;
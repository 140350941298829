import React, { useState, useEffect, useContext, useMemo } from "react";
import ReactGA from "react-ga4";
import { Link, Redirect } from 'react-router-dom';
import {
    Card,
    CardTitle,
    CardBody,
    Form,
    FormGroup,
    FormFeedback,
    Input,
    Button,
    Row,
    Col
} from 'reactstrap';
import AuthContext from '../contexts/AuthContext';
import './Pages.css';

function Signup() {
    const { login, isLoggedIn } = useContext(AuthContext);
    const [isError, setIsError] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);

    // Initialize abortController using useMemo()
    const abortController = useMemo(() => new AbortController(), []);

    // Google Analytics and abortController
    useEffect(() => {
        
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: window.location.pathname,
        });
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    // Validate email field
    const validateEmail = (email) => {
        const emailRegex = /^\S+@\S+\.\S+$/;
        return emailRegex.test(email);
    };

    // Validate password field
    const validatePassword = (password) => {
        return password.length >= 6;
    };

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();

        // Check for validation errors
        if (!emailValid || !passwordValid) {
            setIsError(true);
            return;
        }

        // Create a user object with the user's input data
        const user = {
            firstName,
            lastName,
            email,
            password,
        };


        // Send a POST request to the server to register the user
        fetch(`${process.env.REACT_APP_API_URL}api/v1/register`, {
            signal: abortController.signal,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
        })
            .then((response) => {
                // If the registration response from the server is OK, log in the user in context
                if (response.ok) {
                    return response.json(); // Parse the JSON response
                }
                // If the response from the server is not OK, set an error flag to display an error message
                else {
                    setIsError(true);
                    console.log(response);
                    throw new Error('Registration failed');
                }
            })
            .then((data) => {
                const token = data.token;

                login(token)
                    .then(() => {
                        setEmail("");
                        setFirstName("");
                        setLastName("");
                        setPassword("");
                    })
                    .catch(() => {
                        setIsError(true);
                    });
            })
            .catch(() => {
                setIsError(true);
            });
    };

    if (isLoggedIn) {
        return <Redirect to="/" />;
    }

    return (
        <Card
            className="shadow"
            style={{
                borderRadius: "25px",
                width: "40vw",
                margin: "8vh auto",
            }}
        >
            <CardBody>
                <CardTitle
                    className="cursive-font"
                    style={{
                        fontSize: "2.2em",
                    }}
                >
                    Sign Up
                </CardTitle>

                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailValid(validateEmail(e.target.value));
                            }}
                            placeholder="email"
                            invalid={!emailValid && email !== ""}
                        />
                        {!emailValid && email !== "" && (
                            <FormFeedback>Please enter a valid email</FormFeedback>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col>
                                <Input
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                    }}
                                    placeholder="first name"
                                />
                            </Col>
                            <Col>
                                <Input
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                    }}
                                    placeholder="last name"
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col>
                                <Input
                                    type="password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setPasswordValid(validatePassword(e.target.value));
                                    }}
                                    placeholder="password"
                                    invalid={!passwordValid && password !== ""}
                                />
                                {!passwordValid && password !== "" && (
                                    <FormFeedback>
                                        Password must be at least 6 characters long
                                    </FormFeedback>
                                )}
                            </Col>
                            <Col>
                                {/* Need to verify that password match */}
                                <Input type="password" placeholder="password again" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Button
                            color="primary"
                            disabled={!emailValid || !passwordValid}
                            style={{ marginTop: "1%" }}
                        >
                            Create Account
                        </Button>
                    </FormGroup>
                </Form>
                <Link
                    to="/login"
                    className="uppercase-font"
                    style={{
                        fontSize: "0.9em",
                    }}
                >
                    Already have an account?
                </Link>
                {isError && (
                    <p
                        style={{
                            color: "red",
                            fontSize: "0.9em",
                        }}
                    >
                        Validation error
                    </p>
                )}
            </CardBody>
        </Card>
    );
}


export default Signup;

import About from './About';
import Accessibility from './Accessibility';
import Account from './Account';
import Admin from './Admin/Admin';
import AdminArticles from './Admin/AdminArticles';
import AdminMailingList from './Admin/AdminMailingList';
import AdminMessages from './Admin/AdminMessages';
import AdminUsers from './Admin/AdminUsers';
import Articles from './Articles';
import ArticleDetails from './ArticleDetails';
import Cart from './Cart';
import Careers from './Careers';
import Checkout from './Checkout';
import ComingSoon from './ComingSoon';
import Contact from './Contact';
import CourseDetails from './CourseDetails';
import Courses from './Courses';
import Disclaimer from './Disclaimer';
import Help from './Help';
import Home from './Home';
import Login from './Login';
import NewsletterSignUp from './NewsletterSignUp';
import NewsletterFunnel from './Newsletter';
import Media from './Media';
import Policy from './Policy';
import ProductDetails from './ProductDetails';
import Resources from './Resources';
import Receipt from './Receipt';
import Signup from './Signup';
import Store from './Store';
import Support from './Support';
import Terms from './Terms';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    About,
    Accessibility,
    Account,
    Admin,
    AdminArticles,
    AdminMailingList,
    AdminMessages,
    AdminUsers,
    Articles,
    ArticleDetails,
    Cart,
    Careers,
    Checkout,
    ComingSoon,
    Contact,
    CourseDetails,
    Courses,
    Disclaimer,
    Help,
    Home,
    Login,
    NewsletterSignUp,
    NewsletterFunnel,
    Media,
    Policy,
    ProductDetails,
    Resources,
    Receipt,
    Signup,
    Store,
    Support,
    Terms
}
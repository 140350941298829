import React, {
    useState,
    useEffect
} from "react";
import ReactGA from "react-ga4";
import {
    Link,
    Redirect
} from "react-router-dom";
import {
    Card,
    CardTitle,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup,
    FormFeedback,
    Input,
    Button
} from 'reactstrap';
import { useAuth } from '../contexts/AuthContext';
import './Pages.css';

function Login(props) {
    const { login, isLoggedIn } = useAuth();
    const [isError, setIsError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    // const referer = props.location.state ? props.location.state.referer : '/';
    const referer = props.location.state?.referer || '/';

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    });

    // Validate email field
    const validateEmail = (email) => {
        const emailRegex = /^\S+@\S+\.\S+$/;
        return emailRegex.test(email);
    };

    // Validate password field
    const validatePassword = (password) => {
        return password.length >= 6;
    };

    function postLogin() {

        // Check for validation errors
        if (!emailValid || !passwordValid) {
            setIsError(true);
            return;
        }

        fetch('/api/v1/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                login(data.token);
            })
            .catch(() => {
                setIsError(true);
            });
    }


    if (isLoggedIn) {
        return <Redirect to={referer} />;
    }

    return (
        <Card className='shadow' style={{
            borderRadius: '25px',
            width: '40vw',
            margin: '8vh auto',
        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Log In
                </CardTitle>

                <Form>
                    <FormGroup>
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailValid(validateEmail(e.target.value));
                            }}
                            placeholder="email"
                            invalid={!emailValid && email !== ""}
                        />
                        {!emailValid && email !== "" && (
                            <FormFeedback>Please enter a valid email</FormFeedback>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col>
                                <Input
                                    type="password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setPasswordValid(validatePassword(e.target.value));
                                    }}
                                    placeholder="password"
                                    invalid={!passwordValid && password !== ""}
                                />
                                {!passwordValid && password !== "" && (
                                    <FormFeedback>
                                        Password must be at least 6 characters long
                                    </FormFeedback>
                                )}
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Button color='primary'
                            onClick={postLogin}
                            disabled={!emailValid || !passwordValid}
                            style={{ marginTop: '1%' }}
                        >
                            Log In
                    </Button>
                    </FormGroup>
                </Form>
                <Link to="/signup" className='uppercase-font' style={{
                    fontSize: '0.9em'
                }}>Don't have an account?</Link>

                {isError && <p className='uppercase-font' style={{
                    color: 'red',
                    fontSize: '0.9em'
                }}>The email or password provided were incorrect!</p>}

            </CardBody>
        </Card>
    );
}

export default Login;

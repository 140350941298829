import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import {
    Card,
    CardBody,
    CardTitle,
    CardText
} from 'reactstrap';

function PrivacyPage() {

    // Google Analytics
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname })
    });

    return (
        <Card className='shadow' style={{
            width: '80vw',
            minHeight: '60vh',
            borderRadius: '25px',
            margin: '8vh auto',

        }}>
            <CardBody>
                <CardTitle className='cursive-font' style={{
                    fontSize: '2.2em'
                }}>
                    Privacy
                </CardTitle>
                <CardText>
                    <p>Protecting your privacy is important to us. This Privacy Statement explains how IgniteYourGnost.com collects, uses, and protects your personal information. By using our website, you agree to the terms of this Privacy Statement.</p>

                    <h3>Information We Collect</h3>
                    <p>When you use our website, we may collect certain personal information, such as your name, email address, and other contact information. We may also collect non-personal information, such as your IP address, browser type, and device information.</p>

                    <h3>How We Use Your Information</h3>
                    <p>We may use your personal information to provide you with the products or services you request, to communicate with you about your account or our products and services, or to improve our website and user experience. We may also use your information to send you marketing communications about our products and services, but you may opt-out of receiving these communications at any time.</p>

                    <h3>Information Sharing and Disclosure</h3>
                    <p>We do not sell or rent your personal information to third parties. We may share your information with our service providers who help us operate our website or provide services to you. We may also share your information in response to legal requests or if we believe it is necessary to protect our rights or the rights of others.</p>

                    <h3>Cookies and Tracking Technologies</h3>
                    <p>We may use cookies and other tracking technologies to collect non-personal information about your use of our website. These technologies help us improve our website and provide a better user experience. You may set your browser to refuse cookies, but this may limit your ability to use certain features of our website.</p>

                    <h3>Data Security</h3>
                    <p>We use reasonable measures to protect your personal information from unauthorized access, disclosure, or destruction. However, no data transmission over the internet or electronic storage is completely secure, so we cannot guarantee the security of your information.</p>

                    <h3>Children's Privacy</h3>
                    <p>Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are under the age of 13, please do not use our website or provide any personal information to us.</p>

                    <h3>Changes to this Privacy Statement</h3>
                    <p>We may update this Privacy Statement from time to time. If we make material changes, we will notify you by posting a notice on our website or by sending you an email. Your continued use of our website after the posting of any changes to this Privacy Statement constitutes your acceptance of those changes.</p>

                    <h3>Contact Us</h3>
                    <p>If you have any questions or concerns about our Privacy Statement or our use of your personal information, please contact us at ignitegnost@gmail.com.</p>

                    <p>Thank you for using IgniteYourGnost.com.</p>

                </CardText>
            </CardBody>
        </Card>
    );
}

export default PrivacyPage;